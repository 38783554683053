<template>
  <div style="background: #f6f7f9" v-if="!$isMobile">
    <!-- 招聘动态详情页 -->
    <!-- 里面div都是有作用的 如果要删之前先看一眼🧙🏻‍♂️ 虽然本文几乎只有div🤹🏻‍♂️-->
    <!-- 为了满足产品UI的需求加了很多看着比较奇怪的交互 -->
    <ZHeader></ZHeader>
    <div class="content">
      <div class="tabs" id="topFix" :class="[topFixed ? 'tofixed' : '']">
        <div
          class="tab"
          v-for="(item, index) in tabList"
          :key="index"
          @click="scrollTo('active' + index, index)"
          :class="[hightLine == index ? 'hightLine' : '']"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        class="ecology ecologys"
        id="active0"
        style="border-radius: 0px 0px 16px 16px"
        :class="[topFixed ? 'mt-75' : '']"
      >
        <div class="title">
          <div></div>
          <div>宣讲会</div>
          <div></div>
        </div>
        <div class="meeting">
          <div class="meeting_card" v-for="(item, index) in meetingList" :key="index" :id="'meeting' + index">
            <div class="meeting_title">
              {{ item.lectureName }}
            </div>
            <div class="meeting_time">
              <span v-if="item.startTime">{{ $dayjs(item.startTime).format('YYYY-MM-DD') }}</span>
              <span v-if="item.startTime" style="margin-left: 15px">
                {{ $dayjs(item.startTime).format('HH:mm') }}
              </span>
              <span v-if="item.endTime">~{{ $dayjs(item.endTime).format('HH:mm') }}</span>
            </div>
            <div class="meeting_btn" @click="pushTo(item)">预约报名</div>
            <div class="epoint"><div></div></div>
          </div>
          <div class="empty_content" v-if="!meetingList.length">宣讲会行程解锁中，期待线下的相遇</div>
        </div>
      </div>
      <div class="ecology ecologys" id="active1">
        <div class="title">
          <div></div>
          <div>招聘流程</div>
          <div></div>
        </div>
        <div class="process">
          <process></process>
        </div>
      </div>
      <div class="ecology" id="active2" style="border-radius: 0px 0px 16px 16px">
        <div class="title">
          <div></div>
          <div>校招公告</div>
          <div></div>
        </div>
        <div class="e_content">
          <div v-for="(item, index) in ecologyList" :key="index" class="eCard" :id="'notice' + index">
            <div class="eTitle">{{ item.name }}</div>
            <div class="eTime">{{ $dayjs(item.announcementTime).format('YYYY-MM-DD') }}</div>
            <div class="eContent" v-html="item.remark"></div>
            <div class="epoint"><div></div></div>
          </div>
        </div>
      </div>
      <div class="ecology ecologys" id="active3" style="margin-bottom: 56px">
        <div class="title">
          <div></div>
          <div>答疑专区</div>
          <div></div>
        </div>
        <div class="answer_content">
          <div class="answer_card" v-for="(item, index) in answerList" :key="index" :id="'answer' + index">
            <div class="answer_quesition">
              {{ item.name }}
            </div>
            <div class="answer_answer">
              <div></div>
              <span v-html="item.remark"></span>
            </div>
          </div>
          <div class="open" v-if="dataList.qaInfos && dataList.qaInfos.length > 7">
            <div v-if="open">
              <div class="line" @click="open = false"></div>
              <span>
                展开
                <el-icon><arrow-down-bold /></el-icon>
              </span>
            </div>
            <div>
              <div class="line" @click="close"></div>
              <span>
                收起
                <el-icon><arrow-up-bold /></el-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <z-footer></z-footer>
  </div>
  <div v-else>
    <van-tabs v-model:active="H5ActiveTab" class="h5Tab" style="margin-top: 0.2rem">
      <van-tab title="宣讲会" name="0">
        <div class="h5_cards">
          <div class="h5_cards_title">
            <div class="h5_point"></div>
            <div class="h5_title">宣讲会</div>
          </div>
          <div class="h5_card" v-for="(item, index) in meetingList" :key="index">
            <div style="display: flex">
              <div style="width: 4.31rem">
                <div class="h5_card_title">{{ item.lectureName }}</div>
                <div class="h5_card_time">
                  <span v-if="item.startTime">{{ $dayjs(item.startTime).format('YYYY-MM-DD') }}</span>
                  <span v-if="item.startTime" style="margin-left: 15px">
                    {{ $dayjs(item.startTime).format('HH:mm') }}
                  </span>
                  <span v-if="item.endTime">~{{ $dayjs(item.endTime).format('HH:mm') }}</span>
                </div>
              </div>
              <div style="flex: 1">
                <div class="meeting_btn" @click="pushTo(item)">预约报名</div>
              </div>
            </div>
          </div>
          <div class="h5_empty_content" v-if="!meetingList.length">宣讲会行程解锁中，期待线下的相遇</div>
        </div>
      </van-tab>
      <van-tab title="招聘流程" name="1">
        <div class="h5_cards" style="padding-bottom: 0.33rem">
          <div class="h5_cards_title" style="border: none">
            <div class="h5_point"></div>
            <div class="h5_title">招聘流程</div>
          </div>
          <div class="Trainee">
            <div class="C_steps">
              <div class="C_step" v-for="(item, index) in stepList" :key="index">
                <div class="C_step_left">
                  <div class="C_step_icon"></div>
                  <div class="C_step_line"></div>
                </div>
                <div class="C_step_right">
                  <div class="c-step__name">{{ item.name }}</div>
                  <div v-if="item.descr" class="c-step__descr">{{ item.descr }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="校招公告" name="2">
        <div class="h5_cards">
          <div class="h5_cards_title">
            <div class="h5_point"></div>
            <div class="h5_title">校招公告</div>
          </div>
          <div class="h5_card" v-for="(item, index) in ecologyList" :key="index">
            <div class="h5_card_title">
              {{ item.name }}
            </div>
            <div class="h5_card_time">{{ $dayjs(item.announcementTime).format('YYYY-MM-DD') }}</div>
            <div class="h5_card_content" v-html="item.remark"></div>
          </div>
        </div>
      </van-tab>

      <van-tab title="答疑专区" name="3">
        <div class="h5_cards">
          <div class="h5_cards_title">
            <div class="h5_point"></div>
            <div class="h5_title">答疑专区</div>
          </div>
          <div class="h5_card" v-for="(item, index) in answerList" :key="index">
            <div class="h5_card_title">
              {{ item.name }}
            </div>
            <div style="display: flex">
              <div class="answer_Icon">答</div>
              <div class="h5_card_content answer" v-html="item.remark"></div>
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { defineComponent, onMounted, onBeforeUnmount, reactive, toRefs, getCurrentInstance, computed, ref } from 'vue'
import process from './components/process.vue'
import h5Title from '@/components/title/h5title.vue'
import { ArrowDownBold, ArrowUpBold } from '@element-plus/icons'
import { useRoute, useRouter } from 'vue-router'
import store from '@/store'
import wx from 'weixin-js-sdk'
export default defineComponent({
  components: {
    process,
    ArrowDownBold,
    ArrowUpBold
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const type = ref(null)
    if (route.query && route.query.type) {
      type.value = route.query.type
      console.log('运行了', route.meta.title)
    }
    const state = reactive({
      tabList: [
        { key: '1', name: '宣讲会' },
        { key: '2', name: '招聘流程' },
        { key: '3', name: '校招公告' },
        { key: '4', name: '答疑专区' }
      ],
      ecologyList: [],
      meetingList: [],
      answerList: [],
      dataList: [],
      open: true,
      topFixed: false,
      H5ActiveTab: 0,
      stepList: [
        {
          name: '网申'
        },
        {
          name: '测评'
        },
        {
          name: '笔试',
          descr: '(仅面向IT类岗位)'
        },
        // {
        //   name: '1分钟演讲'
        // },
        {
          name: '无领导小组讨论',
          descr: '(仅面向管培生岗位)'
        },
        {
          name: '1-3轮1V1面试'
        },
        {
          name: 'Offer'
        }
      ],
      hightLine: 0 //用来判断那个地方是高亮的
    })
    onMounted(() => {
      getRecruitmentNews().then(() => {
        if (route.query.ids) {
          getActivityStorage()
        }
        if (route.query.active) {
          if (route.query.active == '3') {
            scrollTo('active0')
          } else if (route.query.active == '4') {
            scrollTo('active3')
          }
        }
        if (route.query.activeName) {
          if (route.query.activeName == '宣讲会') {
            scrollTo('active0')
          } else if (route.query.activeName == '招聘流程') {
            scrollTo('active1')
          } else if (route.query.activeName == '校招公告') {
            scrollTo('active2')
          } else if (route.query.activeName == '答疑专区') {
            scrollTo('active3')
          }
        }
        if (route.query.detailId) {
          scrollTo(route.query.detailId)
        }
      })
      if (!proxy.$isMobile) {
        window.addEventListener('scroll', handleScroll)
      } else {
        state.H5ActiveTab = route.query.active
      }
    })
    onBeforeUnmount(() => {
      if (!proxy.$isMobile) {
        window.removeEventListener('scroll', handleScroll)
      }
    })
    function handleScroll() {
      // 滑动到content区域 让脑袋变成fixed
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var offsetTop = document.getElementById('topFix').offsetTop
      var screenHeight = window.screen.height
      if (scrollTop > offsetTop) {
        state.topFixed = true
      }
      if (scrollTop < 83) {
        state.topFixed = false
      }
      // 滑动到某片区域 让该tab 高亮 ╯︿╰
      var scrollIds = document.getElementsByClassName('ecology')
      for (let i = 0; i < scrollIds.length; i++) {
        if (scrollTop + 200 >= scrollIds[i].offsetTop) {
          state.hightLine = i
        }
      }
    }
    function scrollTo(index, i) {
      let a = document.getElementById(index)

      window.scrollTo({ top: a.offsetTop - 66, behavior: 'smooth' })
      // state.hightLine = i
    }
    // 查询
    function getRecruitmentNews() {
      return proxy.$http.campus.getRecruitmentNews().then((res) => {
        state.dataList = res
        state.ecologyList = res.schoolAnnouncements ? res.schoolAnnouncements : []
        state.meetingList = res.lectureInfos ? res.lectureInfos : []
        state.answerList = res.qaInfos ? res.qaInfos.slice(0, 7) : []
      })
    }
    function getActivityStorage() {
      let data = {
        key: route.query.ids
      }
      proxy.$http.campus.getActivityStorage(data).then((res) => {
        if (res) {
          if (route.query.active == '3') {
            state.ecologyList = [JSON.parse(res), ...state.ecologyList]
          } else if (route.query.active == '4') {
            state.answerList = [JSON.parse(res), ...state.answerList]
          }
        }
      })
    }
    function close() {
      state.answerList = state.dataList.qaInfos
    }

    function isWechat() {
      return /MicroMessenger/i.test(window.navigator.userAgent)
    }

    function pushTo(item) {
      if (isWechat()) {
        wx.miniProgram.getEnv((res) => {
          console.log(' wx.miniProgram', res) // true
          // 在小程序中
          if (res.miniprogram) {
            wx.miniProgram.navigateTo({
              url: `/packageA/pages/lecture/reservation/index?scene=${item.id}`
            })
          }
        })
      } else {
        router.push({ name: 'lecture', query: { id: item.id, name: item.lectureName } })
      }
    }
    return {
      ...toRefs(state),
      scrollTo,
      getRecruitmentNews,
      getActivityStorage,
      open,
      close,
      pushTo,
      handleScroll,
      type
    }
  }
})
</script>
<style lang="scss" scoped>
.empty_content {
  font-size: 19px;
  font-weight: 500;
  color: #333;
}
.h5_empty_content {
  font-size: 0.29rem;
  font-weight: 500;
  color: #666;
  padding: 0.35rem 0.3rem;
  font-family: 'PingFang SC', 'Microsoft YaHei', '微软雅黑', Arial, Helvetica, Arial, sans-serif;
  // margin-left: 0.58rem;
}
.seach_header {
  width: 100%;
  height: 91px;
  background: #ffffff;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  border-top: 3px solid #f6f7f9;
  overflow: hidden;
  margin-top: 53px;
  margin-bottom: 16px;
  z-index: 10;
  .search {
    width: 675px;
    height: 53px;
    background: linear-gradient(137deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
    border-radius: 32px;
    border: 1px solid #d8dfe7;
    margin: 19px 0 0 360px;
    // &:deep(.el-button) {
    //   margin-left: 1px;
    // }
  }
}
.content {
  width: 1200px;
  padding-top: 30px;
  margin: 0 auto;
  overflow: hidden;
  .tabs {
    width: 100%;
    height: 66px;
    display: flex;
    .tab {
      width: 300px;
      box-sizing: border-box;
      background: #eaf0f7;
      border-radius: 16px 16px 0px 0px;
      font-size: 19px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      line-height: 66px;
      cursor: pointer;
    }
    .hightLine {
      background: #ffffff;
    }
  }
  .ecology {
    background: #ffffff;
    box-shadow: 0px 5px 11px 0px rgba(96, 100, 102, 0.1);
    padding: 32px 27px;
    .e_content {
      margin-top: 16px;
      .eCard {
        padding: 32px 0 18px 37px;
        position: relative;
        .eTitle {
          font-size: 19px;
          font-weight: 500;
          color: #333333;
        }
        .eTime {
          font-size: 17px;
          font-weight: 500;
          color: #999999;
          margin-top: 11px;
        }
        .eContent {
          font-size: 17px;
          font-weight: 500;
          color: #666666;
          margin-top: 11px;
          overflow: auto;
        }
      }
    }
    .process {
      padding: 31px 5px 11px;
    }
    .meeting {
      padding: 16px 0;
      .meeting_card {
        padding: 32px 0 20px 37px;
        position: relative;
        .meeting_title {
          font-size: 19px;
          font-weight: 500;
          color: #333333;
          width: 750px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .meeting_time {
          font-size: 17px;
          font-weight: 500;
          color: #999999;
          margin-top: 11px;
        }
        .meeting_btn {
          width: 147px;
          height: 48px;
          border-radius: 27px;
          border: 1px solid #6bafff;
          cursor: pointer;
          position: absolute;
          top: 37px;
          right: 32px;
          line-height: 48px;
          text-align: center;
          font-size: 21px;
          font-weight: 500;
          color: #6bafff;
          &:hover {
            background: linear-gradient(130deg, #66c5ff 0%, #3693ff 100%);
            color: #fff;
          }
        }
      }
      .meeting_card:hover {
        background: #f9fbfc;
      }
    }
    .answer_content {
      padding-top: 15px;
      .answer_card {
        padding-bottom: 37px;
        .answer_quesition {
          width: 1173px;
          height: 58px;
          background: linear-gradient(270deg, #f6f9fc 0%, #eaf0f7 100%);
          border-radius: 0px 35px 35px 0px;
          margin-left: -27px;
          padding: 0 40px 0 27px;
          box-sizing: border-box;
          line-height: 58px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 22px;
          font-weight: 500;
          color: #3693ff;
        }
        .answer_answer {
          padding-left: 37px;
          margin-top: 21px;
          font-size: 17px;
          font-weight: 500;
          color: #666666;
          position: relative;
          & > div {
            position: absolute;
            left: 0;
            top: 3px;
            width: 21px;
            height: 21px;
            background: url(~@/assets/campus/answer.png);
            background-size: cover;
          }
        }
      }
      .open {
        text-align: center;
        .line {
          height: 1px;
          width: 100%;
          background: rgba(227, 232, 238, 1);
        }
        & > span {
          margin-top: 31px;
          font-size: 21px;
          font-weight: 500;
          color: #666666;
          display: inline-block;
        }
      }
    }
  }
}
.title {
  position: relative;
  border-bottom: 1px solid rgba(241, 243, 246, 1);
  & > div:nth-child(1) {
    position: absolute;
    left: 0;
    top: 6px;
    width: 5px;
    height: 26px;
    background: #6bafff;
    border-radius: 3px;
  }
  & > div:nth-child(2) {
    margin-left: 28px;
    font-size: 25px;
    font-weight: 600;
    color: #333333;
  }
  & > div:nth-child(3) {
    width: 100%;
    height: 1px;
    background: rgba(241, 243, 246, 1);
    margin-top: 11px;
  }
}
.ecologys {
  border-radius: 16px;
  margin-top: 21px;
}
.epoint {
  position: absolute;
  width: 21px;
  height: 21px;
  border: 1px solid #eef2f8;
  border-radius: 50%;
  padding: 2px;
  box-sizing: border-box;
  top: 35px;
  left: 0px;
  & > div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #d8d8d8;
  }
}
.h5Tab {
  &:deep(.van-tabs__nav) {
    background: inherit !important;
  }
  &:deep(.van-tabs__line) {
    width: 1.38rem;
    height: 0.04rem;
    background: #57a4ff;
    border-radius: 0.33rem;
  }
  &:deep(.van-tab__text) {
    font-size: 0.29rem;
    color: #333333;
  }
  &:deep(.van-tab--active) {
    .van-tab__text {
      font-weight: 500;
      color: #10a5f9;
    }
  }
}
.h5_cards {
  width: 7.15rem;
  background: #ffffff;
  box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  margin: 0.33rem auto;
  .h5_cards_title {
    height: 1.08rem;
    width: 100%;
    border-bottom: 0.02rem solid #e9edf1;
    position: relative;
    overflow: hidden;
    .h5_point {
      width: 0.08rem;
      height: 0.29rem;
      background: #709af6;
      border-radius: 0.08rem;
      position: absolute;
      left: 0.33rem;
      top: 0.44rem;
    }
    .h5_title {
      font-size: 0.33rem;
      font-weight: 500;
      color: #5a6481;
      margin-top: 0.35rem;
      margin-left: 0.58rem;
    }
  }
}
.h5_card {
  width: 6.48rem;
  margin: auto;
  padding: 0.33rem 0 0.42rem;
  box-sizing: border-box;
  border-bottom: 0.02rem solid #e9edf1;
  .h5_card_title {
    font-size: 0.29rem;
    font-weight: 600;
    color: #333333;
    line-height: 0.44rem;
    .QAIcon {
      width: 0.34rem;
      height: 0.34rem;
      font-size: 0.29rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.29rem;
      background: #b2c0d1;
      border-radius: 0.05rem;
      display: inline-block;
      padding-left: 0.04rem;
      padding-top: 0.04rem;
      margin-right: 0.21rem;
    }
  }
  .h5_card_time {
    font-size: 0.29rem;
    font-weight: 500;
    color: #666666;
    margin-top: 0.17rem;
  }
}
.answer_Icon {
  width: 0.34rem;
  height: 0.34rem;
  font-size: 0.29rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.29rem;
  border-radius: 0.05rem;
  display: inline-block;
  padding-left: 0.04rem;
  padding-top: 0.04rem;
  margin-right: 0.21rem;
  background: #b2c0d1;
  margin-top: 0.28rem;
}
.h5_card_content {
  flex: 1;
  margin-top: 0.25rem;
  font-size: 0.29rem;
  font-weight: 500;
  color: #666666;
  line-height: 0.5rem;
}
.answer {
  ::v-deep p:nth-child(1)::before {
    content: '答';
  }
}
.Trainee {
  width: 6.48rem;
  margin: auto;
  background: #f7f8fc;
  border-radius: 0.25rem;
  overflow: hidden;
}
.h5_process_title {
  font-size: 0.29rem;
  font-weight: 600;
  color: #333333;
  margin-top: 0.33rem;
  margin-left: 0.42rem;
}
.C_steps {
  display: flex;
  flex-flow: column;
  margin-top: 0.54rem;
  margin-left: 0.44rem;
  margin-bottom: 0.46rem;
  .C_step {
    display: flex;
    .C_step_left {
      position: relative;
      height: 0.87rem;
      .C_step_icon {
        width: 0.25rem;
        height: 0.25rem;
        border: 0.04rem solid rgba(16, 165, 249, 0.5);
        border-radius: 50%;
        box-sizing: border-box;
        z-index: 10;
        background: #fff;
        position: relative;
      }
      .C_step_line {
        position: absolute;
        width: 0.02rem;
        // min-width: 1px;
        height: 100%;
        left: 0.11rem;
        top: 0;
        z-index: 1;
        background: rgba(194, 204, 217, 1);
      }
    }
    .C_step_right {
      margin-top: -0.05rem;
      margin-left: 0.27rem;
      font-size: 0.29rem;
      font-weight: 500;
      color: #333333;

      .c-step__name {
        font-weight: 500;
        color: #333333;
        font-size: 0.29rem;
      }
      .c-step__descr {
        color: #999;
        font-size: 0.2rem;
      }
    }
    &:last-child {
      .C_step_left {
        height: inherit;
        .C_step_line {
          width: 0;
          height: 0;
        }
      }
    }
  }
}
.h5_card {
  overflow: hidden;
  .meeting_btn {
    width: 1.5rem;
    height: 0.5rem;
    border-radius: 0.42rem;
    border: 0.02rem solid #57a4ff;
    line-height: 0.5rem;
    font-size: 0.29rem;
    font-weight: 500;
    color: #57a4ff;
    text-align: center;
    float: right;
    margin-top: 0.23rem;
  }
}
.tofixed {
  position: fixed;
  top: 0;
  z-index: 999;
}
.mt-75 {
  overflow: hidden;
  margin-top: 75px;
}
</style>
