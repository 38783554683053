<!--
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-02-10 16:55:08
 * @LastEditTime: 2024-01-26 14:59:49
-->
<template>
  <!-- 员工故事详情开发 -->
  <div class="detail" v-if="!$isMobile">
    <ZHeader></ZHeader>
    <!-- <div class="banner" :style="{ background: `url(${detail.photoUrl})`, backgroundSize: 'cover' }"></div> -->
    <div class="banner" :style="{ background: `rgba(${colors}` }">
      <img ref="imgRef" :src="detail.photoUrl" crossOrigin="anonymous" />
    </div>
    <div class="content">
      <div class="bgi"></div>
      <div class="title">
        {{ detail.name }}
      </div>
      <div class="Subtitle">
        {{ detail.filterName }}
      </div>
      <div class="split">
        <div></div>
        <div></div>
      </div>
      <div class="content_content" v-html="detail.remark"></div>
    </div>
    <z-footer></z-footer>
  </div>
  <div v-else class="h5_detail">
    <img class="h5_banner" :src="detail.photoUrl" />
    <div class="h5_content">
      <div class="h5_title">{{ detail.name }}</div>
      <div class="h5_subTitle">
        {{ detail.filterName }}
      </div>
      <div class="h5_substance" v-html="detail.remark"></div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, toRefs, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import store from '@/store'
import ColorThief from 'colorthief'
export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      detail: {}
    })
    const colors = ref(null)
    const imgRef = ref(null)
    function getDetails() {
      let data = {
        id: route.query.id
      }
      proxy.$http.campus.getActivityInfo(data).then((res) => {
        if (res) {
          state.detail = res[0]
          const colorThief = new ColorThief()
          imgRef.value.onload = () => {
            const color = colorThief.getColor(imgRef.value)
            console.log('color', color)
            colors.value = color.join(',')
          }
        }
      })
    }
    onMounted(() => {
      console.log('set')
      if (route.query.ids) {
        getActivityStorage()
      } else {
        getDetails()
      }
    })
    function getActivityStorage() {
      let data = {
        key: route.query.ids
      }
      proxy.$http.campus.getActivityStorage(data).then((res) => {
        if (res) {
          state.detail = JSON.parse(res)
        } else {
          router.push({ path: '/' })
        }
      })
    }

    return {
      ...toRefs(state),
      getDetails,
      getActivityStorage,
      imgRef,
      colors
    }
  }
})
</script>
<style lang="scss" scoped>
.detail {
  width: 100%;
  // height: 100%;
}
.banner {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    flex-grow: 2;
    // width: 100%;
    min-width: 1100px;
    height: 100%;
    object-fit: contain;
  }
}
.content {
  width: 1000px;
  margin: 0 auto;
  position: relative;
  padding-top: 136px;
  .bgi {
    position: absolute;
    top: 36px;
    left: -75px;
    width: 115px;
    height: 115px;
    background: url(~@/assets/ControlOn.png);
    background-size: cover;
    z-index: 0;
  }
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #333;
    position: absolute;
    top: 83px;
    left: 0;
  }
  .Subtitle {
    font-size: 21px;
    font-weight: 500;
    color: #666666;
  }
  .split {
    width: 100%;
    margin-top: 29px;
    margin-bottom: 40px;
    & > div:nth-child(1) {
      width: 43px;
      height: 5px;
      background: #3693ff;
      border-radius: 3px;
      display: inline-block;
    }
    & > div:nth-child(2) {
      width: 1131px;
      height: 3px;
      background: #e3e8ee;
      display: inline-block;
      margin-left: 20px;
    }
  }
  .content_content {
    font-size: 16px;
    // font-weight: 500;
    color: #666666;
    margin-bottom: 100px;
    // line-height: 28px;
  }
}
.h5_detail {
  width: 100%;
  background: #fff;
  .h5_banner {
    width: 100%;
  }
  .h5_content {
    width: 100%;
    padding: 0.5rem 0.33rem;
    box-sizing: border-box;
    .h5_title {
      font-size: 0.38rem;
      font-weight: 600;
      color: #333333;
    }
    .h5_subTitle {
      font-size: 0.25rem;
      font-weight: 500;
      color: #666666;
      line-height: 0.44rem;
      margin-top: 0.21rem;
    }
    .h5_substance {
      font-size: 0.29rem;
      // font-weight: 500;
      color: #666666;
      // line-height: 0.5rem;
      margin-top: 0.25rem;
    }
  }
}
</style>
