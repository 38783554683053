<!--
 * @Author: chengweijia-tel:18702108979
 * @Version: 1.0
 * @Date: 2022-01-18 15:37:17
 * @LastEditTime: 2022-01-18 15:41:11
-->
<template>
  <div>404</div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped></style>
